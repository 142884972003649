body {
  /* background-color: #151d2d; */
  background-color: #151d2d;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
}

.logo {
  max-width: 8em;
}

h1{
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 2em;
  margin: 0;
  color: #fff;
}

#Spotify-Player {
  padding: 0;
  margin-top: 1em;
  width: 90%;
  max-width: 900px;

}
/* 
path {
  fill: #151d2d;
}
 */